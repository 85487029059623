import Vue from 'vue';
import './axios.js';
import { BootstrapVue} from 'bootstrap-vue';
import Toasted from 'vue-toasted';
// Vue.use(VueAxios, axios);
Vue.use(Toasted, {
    theme: "outline",
    position: "top-center",
    duration : 5000
})
Vue.use(BootstrapVue);
Vue.component('table-manutenzioni', require('./components/TableManutenzioni').default);
const app = new Vue({
    el: '#app',
});
