import Vue from 'vue';
import AxiosStatic from 'axios';
import VueAxios from 'vue-axios';
import {APP_URL} from "./config.js";
let token = document.head.querySelector('meta[name="csrf-token"]');

if (!token) {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

let axios = AxiosStatic.create({
    baseURL: APP_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': token.content
    }
});
window.axios = axios;
Vue.use(VueAxios, axios);

export default axios;
