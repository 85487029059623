<template>
    <div class="container">
        <div>
            <b-row>
                <b-col lg="6" class="my-1">
                    <b-form-group
                        label="Filtra"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Scrivi per Cercare"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button id="button-filtra" :disabled="!filter" @click="filter = ''">Pulisci</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="6" class="my-1">
                    <b-button @click="show = !show" size="sm">?</b-button>
                    <b-form-group v-if="show"   label="Ricerca un campo, con c:01/01/2020 si ricercano le manut per data caricamento" label-for="input-sm">
                    </b-form-group>
                </b-col>

            </b-row>
            <b-row>
                <b-col class="d-flex justify-content-center">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </b-col>
            </b-row>
            <b-row>
                <b-table ref="sstable"
                         :items="items" :fields="fields" caption-top responsive
                         striped hover sticky-header :filter="filter" @filtered="onFiltered" small
                         :per-page="perPage"
                         :current-page="currentPage">
                    <template v-slot:cell(show_details)="row">
                        <b-button size="sm" @click="row.toggleDetails" class="mr-2"
                                  v-bind:class="{ 'success': ! isExpired(row.mn_DataPrevista), 'danger': isExpired(row.mn_DataPrevista)}">
                            {{ row.detailsShowing ? 'Nascondi' : 'Mostra'}} Dettagli
                        </b-button>
                    </template>

                    <template v-slot:row-details="row">
                        <b-card>
                            <b-row class="mb-1">
                                <b-col cols="12" class="text-sm-left">Cliente: <b>{{ row.item.cl_Cognome}} {{ row.item.cl_Nome}} {{ row.item.cl_RagioneSociale}}</b></b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="12" class="text-sm-left">Telefono: <a v-if="row.item.cl_Telefono!==null"  :href="'tel:'+row.item.cl_Telefono" class="btn btn-outline-info">{{ row.item.cl_Telefono}}</a></b-col>
                                <b-col cols="12" class="text-sm-left">Cellulare: <a v-if="row.item.cl_CellulareReferente!==null" :href="'tel:'+row.item.cl_CellulareReferente" class="btn btn-outline-info">{{ row.item.cl_CellulareReferente}}</a></b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="9">
                                    <b-row class="mb-1">
                                        <b-col cols="12" class="text-sm-left">Indirizzo: <b>{{ row.item.cl_Indirizzo}}</b></b-col>
                                        <b-col cols="12" class="text-sm-left">Località: <b>{{ row.item.cl_Localita}}</b></b-col>
                                        <b-col cols="12" class="text-sm-left">Provincia: <b>{{ row.item.cl_Provincia}}</b></b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="3" class="">
                                        <a class="btn btn-outline-info" :href="'http://maps.google.com/?q='+ row.item.cl_Indirizzo+','+ row.item.cl_Localita+','+ row.item.cl_Provincia" target="_blank">Vai</a>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="12" class="text-sm-left">Prodotto: <b>{{ row.item.mn_Prodotto}}</b></b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="12" class="text-sm-left">Data Prevista: <b>{{ row.item.mn_DataPrevista}}</b> | Effettuata:{{ row.item.mn_DataEffettuata}}</b-col>
                                <b-col cols="12" class="text-sm-left">Data Effettuata Net:
                                    <b><b-badge :variant="[(row.item.mn_DataEffettuata_Net===null) ? 'light' : 'success']">{{ row.item.mn_DataEffettuata_Net}}</b-badge></b>
                                </b-col>
                                <b-col cols="12" class="text-sm-left">Data Caricata: <b>{{ row.item.created_at_str}}</b> </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="12" class="text-sm-left">Note: <b>{{ row.item.mn_Note}}</b></b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col cols="12">
                                    <b-button block  :variant="[(row.item.mn_DataEffettuata_Net===null) ? 'success' : 'warning']" @click="setManaged(row.item)">Imposta Come {{ row.item.mn_DataEffettuata_Net ? 'Non Eseguita' : 'Eseguita'}}</b-button>
                                </b-col>
                            </b-row>
                            <hr>
                            <h3>Fai una segnalazione:</h3>
                            <b-row class="my-3" v-if="row.item.mn_SegnalazioneNet===0 || row.item.mn_SegnalazioneNet===null">
                                    <b-col cols="6">
                                        <b-button @click="setSegnalazione(row.item,1)" variant="info" size="sm" block >Non Vuole Più Manutenzione</b-button>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-button @click="setSegnalazione(row.item,2)" variant="info" size="sm" block >Cambiato Indirizzo</b-button>
                                    </b-col>
                            </b-row>
                            <b-row class="my-3" v-if="row.item.mn_SegnalazioneNet!==0 && row.item.mn_SegnalazioneNet!==null">
                                <b-col cols="6">
                                        <b-alert show variant="info" v-if="row.item.mn_SegnalazioneNet===1" block>Segnalato Non Vuole più Manutenzione</b-alert>
                                        <b-alert show variant="info" v-if="row.item.mn_SegnalazioneNet===2" block>Segnalato Cambiato Indirizzo</b-alert>
                                </b-col>
                                <b-col cols="6">
                                        <b-button @click="setSegnalazione(row.item,0)" variant="info" size="sm" block >Rimuovi Segnalazione</b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
            </b-row>
        </div>
    </div>
</template>

<script>
    import {APP_URL} from "../config.js";
    export default {
        data() {
            return {
                perPage: 100,
                currentPage: 1,
                fields: [   { key: 'cl_Cognome', label: 'Cognome' },
                            { key: 'cl_Nome', label: 'Nome' },
                            { key: 'cl_RagioneSociale', label: 'Ragione Sociale' },
                            // { key: 'cl_Localita', label: 'Localita' },
                            { key: 'show_details', label: 'Dettagli' },
                        ],
                items: [
                    {  mn_IDManutenzione: null,
                        cl_RagioneSociale: null,
                        cl_Cognome: null,
                        cl_Nome: null,
                        cl_Indirizzo: null,
                        cl_Localita: null,
                        cl_Provincia: null,
                        cl_Telefono: null,
                        cl_CellulareReferente: null,
                        mn_Prodotto: null ,
                        mn_DataPrevista: null,
                        mn_DataEffettuata: null,
                        mn_DataEffettuata_Net: null,
                        mn_SegnalazioneNet: null,
                        created_at_str: null,
                        mn_Note: null},
                ],
                filter: null,
                show: false,
            }
        },
        mounted() {
            let endpoint=APP_URL+'/manutenzioni/getlist';
            axios.get(endpoint, {withCredentials: true}).then((response) => {
                // console.log(response.data)
                this.items=response.data;
            })
        },
        methods: {
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                // this.totalRows = filteredItems.length
                // this.currentPage = 1
            },
            setManaged(item){
                let endpoint=APP_URL+'/manutenzioni/togglemanaged';
                let Vue=this;
                let _setManaged=  item.mn_DataEffettuata_Net===null ? true : false;
                console.log("_setManaged:",_setManaged);
                this.axios.post(endpoint,
                    {
                            mn_IDManutenzione: item.mn_IDManutenzione,
                            setManaged: _setManaged,
                        })
                    .then((response) => {
                        //faccio il toggle
                        console.log(response.data.data_impostata);
                        item.mn_DataEffettuata_Net = response.data.data_impostata;
                        Vue.$toasted.show("<alert class='bg-success p-4 text-white w-100'>Operazione Corretta</alert>");
                        this.$refs.sstable.refresh();
                    })
                    .catch(function (error) {
                        // handle error
                        Vue.$toasted.show("<alert class='bg-danger p-4  text-white'>"+error.message+"</alert>");
                    });
            },
            setSegnalazione(item,_mn_SegnalazioneNet){
                let endpoint=APP_URL+'/manutenzioni/setsegnalazione';
                let Vue=this;
              //  let _setManaged=  item.mn_DataEffettuata_Net===null ? true : false;
                console.log("setsegnalazione:",_mn_SegnalazioneNet);
                this.axios.post(endpoint,
                    {
                        mn_IDManutenzione: item.mn_IDManutenzione,
                        mn_SegnalazioneNet: _mn_SegnalazioneNet,
                    })
                    .then((response) => {
                        //faccio il toggle
                      //  console.log(response.data.data_impostata);
                        item.mn_SegnalazioneNet = response.data.mn_SegnalazioneNetImpostata;
                        Vue.$toasted.show("<alert class='bg-success p-4 text-white w-100'>Operazione Corretta</alert>");
                        this.$refs.sstable.refresh();
                    })
                    .catch(function (error) {
                        // handle error
                        Vue.$toasted.show("<alert class='bg-danger p-4  text-white'>"+error.message+"</alert>");
                    });
            },
            isExpired(_data){
                return (Date.parse(_data) > new Date()) ? true : false;
            },
            onOpen() {
                this.$refs.tooltip.$emit('open')
            },
            onClose() {
                this.$refs.tooltip.$emit('close')
            }
        },
        computed: {
            rows() {
                return this.items.length
            }
        }
    }
</script>
